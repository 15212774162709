import React from 'react'
import Header from '../components/Header'
import IntroSection from '../components/IntroSection'
import CaseStudySection from '../components/CaseStudySection'
import BusinessSection from '../components/BusinessSection'
import IndustriesSection from '../components/IndustriesSection'
import FounderSection from '../components/FounderSection'
import TrustedBySection from '../components/TrustedBySection'
import ContactSection from '../components/ContactSection'


const Home = () => {
  return (
    <>
        <Header/>
        <IntroSection/>
        <BusinessSection/>
        <IndustriesSection/>
        <CaseStudySection/>
        <FounderSection/>
        <TrustedBySection/> 
        <ContactSection/>       
    </>
  )
}

export default Home