import React, { useState } from "react";
import styled from "styled-components";
import * as constant from "../contents/constant";
//import logoImg from "../assets/logo.png"; // Adjust the path as needed

import { FaBars, FaTimes } from "react-icons/fa";

// Styled components for the navbar
const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 20rem 3rem 18rem;
    background-color: #09233c;
    color: #fff;

    @media (max-width: 768px) {
    padding: 1rem 2rem 1rem 1rem;
    background: linear-gradient(to bottom, #102d4a, #1f476d);
  }
`;

const Logo = styled.div`
    display: flex;
    align-items: center;
    font-size: 4.5rem;
    font-weight: bold;
    cursor: pointer;
    color: black;
    @media (max-width: 768px) { /* For screens 768px and smaller */
        color: white; /* Change text color to white in mobile view */
    }
`;

const LogoImage = styled.img`
    height: 6rem; /* Increased size */
    width: auto;
    margin-right: 1rem; /* Space between image and text */
`;


const InfoItemHead = styled.p`
    margin: 0;
    line-height: 1.4;
    font-size: 2rem;
    font-weight: 600;
`;

const InfoItem = styled.p`
    margin: 0;
    line-height: 1.4;
`;

const ContactItem = styled.div`
    display: flex;
    flex-direction: row; /* Align items in a row */
    align-items: center; /* Vertically center-align items */
    gap: 1rem; /* Add spacing between the image and text */
`;
const StyledImage = styled.img`
  //  filter: brightness(0) saturate(100%) invert(74%) sepia(44%) saturate(546%) hue-rotate(140deg) brightness(90%) contrast(90%);
`;

const InfoContainer = styled.div`
    display: flex;
    color: #ffffff;
    flex-direction: column; /* Stack the heading and text vertically */
`;

const ContactInfo = styled.div`
    display: flex;
    align-items: center;
    font-size: 1rem;
    gap: 4rem;
    color: black;

     @media (max-width: 768px) {
    display: none; 
  }
`;

const Divider = styled.div`
    width: 2px;
    background-color: #ccc;
    height: 4rem;
    align-self: center;
`;

const HamburgerMenu = styled.div`
  display: none;
  font-size: 2rem;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
    color: #ffffff;
  }
`;
const SlideMenu = styled.div`
  position: fixed;
  top: 0;
  right: -1px;
  width: 300px;
  height: 100vh;
  background-color: #1e466b;
  box-shadow: -4px 0 6px rgba(0, 0, 0, 0.2);
  transform: ${(props) => (props.isOpen ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  padding: 2rem;
`;

const CloseButton = styled.div`
  text-align: right;
  font-size: 2rem;
  cursor: pointer;
  margin-bottom: 2rem;
   color: #ffffff;

`;

const SlideMenuItem = styled.div`
  padding: 1.5rem 1rem;
  border-bottom: 1px solid #ccc;
  font-size: 1.5rem;

  &:last-child {
    border-bottom: none;
  }
`;

const Header = () => {

    const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  // Handler to close menu and perform any action
  const handleMenuItemClick = (targetId) => {
    setMenuOpen(false); // Close the menu
    document.getElementById(targetId).scrollIntoView({ behavior: 'smooth' }); // Scroll to the target section
  };

    return (
        <HeaderContainer id="header">
            {/* Left Side: Logo with Image */}
            <Logo>
            <LogoImage src="Images/logo1.png" alt="Logo" onClick={() => window.location.reload()}/>
            </Logo>

            {/* Right Side: Contact Info */}
            <ContactInfo>
            <ContactItem>
                    <StyledImage src="/Images/letter.png" alt="Email Icon" />
                    <InfoContainer>
                        <InfoItemHead>{constant.emailUs}</InfoItemHead>
                        <InfoItem>contact@innovateideaz.com</InfoItem>
                    </InfoContainer>
                </ContactItem>
                <Divider />
                <ContactItem>
                    <StyledImage src="/Images/call.png" alt="Call Icon" />
                    <InfoContainer>
                        <InfoItemHead>{constant.CallUs}</InfoItemHead>
                        <InfoItem>(+49) 176-8227-4604</InfoItem>
                    </InfoContainer>
                </ContactItem>
            </ContactInfo>


            {/* Hamburger Menu */}
            <HamburgerMenu onClick={toggleMenu}>
        <FaBars />
      </HamburgerMenu>

      {/* Sliding Menu */}
      <SlideMenu isOpen={menuOpen}>
        <CloseButton onClick={toggleMenu}>
          <FaTimes />
        </CloseButton>
        <SlideMenuItem onClick={() => handleMenuItemClick("header")} >Home</SlideMenuItem>
        <SlideMenuItem onClick={() => handleMenuItemClick("about")} >About</SlideMenuItem>
        <SlideMenuItem onClick={() => handleMenuItemClick("service")} >Services</SlideMenuItem>
        <SlideMenuItem onClick={() => handleMenuItemClick("contact")} >Contact</SlideMenuItem>
      </SlideMenu>
    </HeaderContainer>
    );
};

export default Header;
