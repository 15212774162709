import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import { GlobalStyle } from './pages/GlobalStyle';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
        <GlobalStyle/>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/service' element={<Services />} />
          <Route path='/contact' element={<Contact/>} />
        </Routes>
        <Footer/>
    </Router>
  );
}

export default App;
