import React from 'react';
import styled from 'styled-components';

const TrustedBySection = () => {
  return (
    <Wrapper>
      <div className="heading">Trusted By </div>
      <p className="content">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
        molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
        numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
        optio, eaque rerum! Provident similique accusantium nemo autem.
      </p>
      <div className="brand-section-slider">
        <div className="slide">
          <img src="Images/T1.png" alt="trusted-brands" />
        </div>
        <div className="slide">
          <img src="Images/T2.png" alt="trusted-brands" />
        </div>
        <div className="slide">
          <img src="Images/T3.png" alt="trusted-brands" />
        </div>
        <div className="slide">
          <img src="Images/T4.png" alt="trusted-brands" />
        </div>
        <div className="slide">
          <img src="Images/T5.png" alt="trusted-brands" />
        </div>
        <div className="slide">
          <img src="Images/T6.png" alt="trusted-brands" />
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 6rem 0;
  background-color: #f9f9f9;
  display: none;

  // h3 {
  //   text-align: center;
  //   text-transform: capitalize;
  //   color: black;
  //   font-size: 3rem;
  //   font-weight: bold;
  // }


    
    @media (max-width: 768px) {
        padding: 3rem 0;
        background-color: #09233c;
    }




     .heading {
    text-align: center;
    text-transform: capitalize;
    color: black;
    font-size: 3rem;
    font-weight: 800;
    letter-spacing: 2px;


    @media (max-width: 768px) {
   
      font-size: 3.5rem;
      color: #ffffff;
     // padding: 4rem 0;
    }

    // @media (max-width: 480px) {
    //   font-size: 2rem;
    // }




  }
  
  .content{
    text-align: center;
    color: black;
    font-size: 13px;
    font-weight: 500;
    padding: 1rem 15rem;
    letter-spacing: 1px;


   @media (max-width: 768px) {
      padding: 2rem 3rem;
      font-size: 0.9rem;
      color: #ffffff;
    }

    // @media (max-width: 480px) {
    //   padding: 1rem 1rem;
    //   font-size: 0.8rem;
    // }    
  }


  .brand-section-slider {
    margin-top: 3.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10rem;


    @media (max-width: 768px) {
      padding: 0 2rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-top: 0;
    }

    // @media (max-width: 480px) {
    //   padding: 0 1rem;
    //   gap: 1rem;
    // }
 

  }

  .slide {
    flex: 1;
    display: flex;
    justify-content: center;


     @media (max-width: 480px) {
      padding: 0.5rem;
    }
  }

  img {
    width: 100px; /* Set a fixed width */
    height: 100px; /* Set a fixed height */
    object-fit: contain; /* Ensures image is scaled without distortion */


    @media (max-width: 768px) {
      width: 65px;
      height: 65px;
    }

    // @media (max-width: 480px) {
    //   width: 50px;
    //   height: 50px;
    // }
  }
`;

export default TrustedBySection;
