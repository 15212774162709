import React from 'react';
import { useState } from 'react';
import axios from 'axios';
import styled from "styled-components";
import { Button } from "./Button";
import { FaLinkedinIn, FaInstagram, FaTwitter } from "react-icons/fa";

//import imageSrc from "../Images/pexels-divinetechygirl-1181406.jpg";  // Import the image



// Styled components for the section container, image, overlay, and text
const SectionContainer = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
 //   border-radius: 10px;

    .h-line{
      margin: 2rem 0;
    } 

   .btn{
   padding: 1rem 2rem;
    margin-top: 0.5rem;
    background-color: #007bff;
    color: #000000;
    font-weight: 900;
    border: none;
    border-radius: 3px;
    font-size: 1rem;
    cursor: pointer;
    &:hover {
        background-color: #0056b3;
    }

    }


    @media (max-width: 768px) {
        font-size: 1.5rem;
     //   margin: 1rem 0rem;
      //  padding: 1rem 1rem;
        font-weight: bold;
    }
   }

    @media (max-width: 768px) {
        height: 50rem;
    }

`;

const Image = styled.img`
    width: 100%;
    height: 500px;
    object-fit: cover;
    display: block;

     @media (max-width: 768px) {
        height: 50rem;
    }
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 2rem;
    text-align: center;


     @media (max-width: 768px) {
        height: 50rem;
        background-color: rgb(0 88 151 / 59%);
    }
`;

const ContactContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
    height: 70%;
    max-width: 1200px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    overflow: hidden;
    opacity: 0.83;
   
    @media (max-width: 768px) {
        flex-direction: column;
        width: 90%;
        height: auto;
    }
`;

const LeftColumn = styled.div`
    flex: 2;
    padding: 4rem;
    background: linear-gradient(to bottom, #1a3850, #09233c);
    text-align: left;

     @media (max-width: 768px) {
        padding: 2rem 3rem;
        background: #153149;
        text-align: center;
    }   
`;

const RightColumn = styled.div`
   // flex: 1;
    padding: 2rem;
    background: #ffffff;
    color: #000000;
    text-align: left;

    @media (max-width: 768px) {
        padding: 1.5rem;
         display: none;
    }
`;

const Heading = styled.h1`
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: bold;


    @media (max-width: 768px) {
        font-size: 3.5rem;
        margin-bottom: 0;
       
    }
    `;

const Title = styled.span`
    font-size: 1rem;
    font-weight: 600;
    
    @media (max-width: 768px) {
        font-size: 1.5rem;
        font-weight: 500;
    }
 `;
const ContactForm = styled.form`
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    display: flex;
    gap: 1rem;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 0rem;
    }
`;

const Input = styled.input`
    width: 100%;
    padding: 0.75rem;
    margin: 0.5rem 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    flex: 1; /* Allow inputs to take equal space in each row */

     @media (max-width: 768px) {
        font-size: 0.9rem;
        border:none;
    }
`;

const TextArea = styled.textarea`
    width: 100%;
    padding: 0.75rem;
    margin: 0.5rem 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    resize: none;

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }
`;


const ContactInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1rem;

     @media (max-width: 768px) {
        font-size: 0.9rem;
    }
`;


const InfoItemHead = styled.p`
    margin: 0;
    line-height: 1.4;
    font-size: 1.3rem;
    font-weight: 900;
`;

const InfoItem = styled.p`
    margin: 0;
    line-height: 1.4;
    font-size: 1rem;
 `;

const ContactItem = styled.div`
    display: flex;
    flex-direction: row; /* Align items in a row */
    align-items: center; /* Vertically center-align items */
    gap: 1rem; /* Add spacing between the image and text */
`;


const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem; /* Adjust size as needed */
    height: 3rem;
    background-color: #54d7f5;
    border-radius: 50%; 
    margin-top: 1rem;
`;

const StyledImage = styled.img`
    width: 1.5rem; /* Adjust size as needed */
    height: 1.5rem;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
`;

const IconContainerr = styled.div`
 display: none;
  
 
    @media (max-width: 768px) {
     display: flex;
        
      
        justify-content: center;
        gap: 1rem;
        font-size: 1.5rem;
        color: #3bc4b2;
        
        svg {
            cursor: pointer;
              margin-top: 1.5rem;
            font-size: 2rem;
            color: #ffffff;
            transition: color 0.3s;
            
}
}
  }
`

const IconContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  gap: 1rem;
  font-size: 1.5rem;
  color: #3bc4b2;

  svg {
    cursor: pointer;
     color: #0f2841;
    transition: color 0.3s;

    &:hover {
    color: #f0a500;
    }
  }
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column; 
    margin-top: 1rem;
`;

const ContactSection = () => {

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
    });

    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const brevoApiKey = "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";
        // Replace with your Brevo API key
        const emailData = {
            sender: {
                email: "sandeep.k@coachsandeepkhaira.com",                             // Take sender's email from the form
                name: `${formData.firstName} ${formData.lastName}`, // Combine first and last name from the form
            }, // Your verified Brevo sender email
            to: [{ email: "sandeep.k@coachsandeepkhaira.com", name: "Innovate Ideaz" }], // The email to receive queries
            subject: "Innovate Ideaz",
            htmlContent: `
            <h1>Contact Form Submission</h1>
            <p><strong>First Name:</strong> ${formData.firstName}</p>
            <p><strong>Last Name:</strong> ${formData.lastName}</p>
            <p><strong>Email:</strong> ${formData.email}</p>
            <p><strong>Phone:</strong> ${formData.phone}</p>
            <p><strong>Message:</strong> ${formData.message}</p>
        `,
        };

        try {
            await axios.post(
                "https://api.brevo.com/v3/smtp/email",
                emailData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "api-key": brevoApiKey,
                    },
                }
            );
            setIsSubmitted(true);
        } catch (error) {
            console.error("Error sending email:", error);
        }
    };



    return (
        <SectionContainer id="contact">
            <Image src="Images/pexels-divinetechygirl-1181406.jpg" alt="Homepage Section" />
            <Overlay>
                <ContactContainer>
                    {/* Left Column with Form */}
                    <LeftColumn>
                        <Title> LET'S CONNECT</Title>
                        <Heading>Get In Touch</Heading>
                        {isSubmitted ? (
                            <p>Thank you for reaching out! We'll get back to you soon.</p>
                        ) : (
                            <ContactForm onSubmit={handleSubmit}>
                                <Row>
                                    <Input
                                        type="text"
                                        name="firstName"
                                        placeholder="First Name"
                                        required
                                        value={formData.firstName}
                                        onChange={handleChange}
                                    />
                                    <Input
                                        type="text"
                                        name="lastName"
                                        placeholder="Last Name"
                                        required
                                        value={formData.lastName}
                                        onChange={handleChange}
                                    />
                                </Row>
                                <Row>
                                    <Input
                                        type="email"
                                        name="email"
                                        placeholder="Your Email"
                                        required
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                    <Input
                                        type="tel"
                                        name="phone"
                                        placeholder="Phone Number"
                                        required
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                </Row>
                                <TextArea
                                    name="message"
                                    placeholder="Your Message"
                                    rows="4"
                                    required
                                    value={formData.message}
                                    onChange={handleChange}
                                />
                                <div>
                                    <Button className="btn" type="submit">Submit</Button>
                                </div>
                            </ContactForm>
                        )}
                    </LeftColumn>

                    {/* Right Column with Contact Info */}
                    <RightColumn>
                        <Heading>Contact Info</Heading>
                        <ContactInfo>
                            <ContactItem>
                                <IconWrapper>
                                    <StyledImage src="/Images/letter.png" alt="Call Icon" />
                                </IconWrapper>
                                <InfoContainer>
                                    <InfoItemHead>Email Us</InfoItemHead>
                                    <InfoItem>contact@innovateideaz.com</InfoItem>
                                </InfoContainer>
                            </ContactItem>

                            <ContactItem>
                                <IconWrapper>
                                    <StyledImage src="/Images/call.png" alt="Call Icon" />
                                </IconWrapper>
                                <InfoContainer>
                                    <InfoItemHead>Call Us</InfoItemHead>
                                    <InfoItem>(+49) 176-8227-4604</InfoItem>
                                </InfoContainer>
                            </ContactItem>
                        </ContactInfo>
                        <hr className="h-line" />
                        <InfoContainer>
                            <InfoItemHead>Social Media</InfoItemHead>
                            <IconContainer>
                                <a
                                    href="https://www.linkedin.com/company/innovate-ideaz-technology/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaLinkedinIn />
                                </a>
                                {/* <FaTwitter />
                                <FaInstagram /> */}
                            </IconContainer>
                        </InfoContainer>
                    </RightColumn>



                </ContactContainer>
            </Overlay>
        </SectionContainer>
    );
};

export default ContactSection;
