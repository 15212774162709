import styled from "styled-components";

export const Button = styled.button` 
  padding: 1.4rem 2.4rem;
  font-size: 1.5rem;
  font-weight: bold;
  background: linear-gradient(to bottom, #fdcd1f, #dfad00);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
    transform: scale(1.05);
  }
`;
