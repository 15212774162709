import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { Button } from "./Button";

const CaseStudySection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    customPaging: (i) => (
      <div className="custom-dots">
        <div className="bar" />
      </div>
    ),
    dotsClass: "slick-dots custom-dots-wrapper",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
         // slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Wrapper>
      <div className="container">
        <div className="grid grid-two-column center">
          <div className="common-heading">
            <h4>Our Case Studies</h4>
            <p className="p-text">
              Discover how we have helped various businesses achieve their goals. Discover 
              how we have helped various businesses achieve their goals.
            </p>
          </div>
          <div className="btn">
            <Button>See All Case Studies</Button>
          </div>
        </div>

        {/* Slider Section */}
        <div className="slider-container">
          <Slider {...settings}>
            {Array.from({ length: 4 }).map((_, index) => (
              <div key={index} className="card">
                <img
                  src={`Images/case study 0${(index % 3) + 1}.png`}
                  alt={`Case Study 0${(index % 3) + 1}`}
                />
                <div className="overlay">
                  <h3>Case Study {index + 1}</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 9rem 10rem;
  background-color: #09233c;

  display: none;


  h4{
    color: #ffffff;
     margin-bottom: 1rem;
  }

  .btn{
    background: linear-gradient(to bottom, #fdcd1f, #dfad00);
    color: #000000;
    font-weight: 900;
    font-size: 1.6rem;
    border-radius: 5px;
  }

  .center{
  align-items: center;
  }

  .container {
    text-align: center;
  }

  .common-heading {
    margin-bottom: 2rem;
    text-align: left;
    font-weight: 900;
  }

  .p-text {
    color: #96abc0;
    font-size: 1.1rem;
    line-height: 1.6;
    margin: 4px auto;
    max-width: 600px;
   // font-weight: 500;
   letter-spacing: 1px;
  }

 .slider-container {
    margin-top: 3rem;

    .slick-slide {
      padding: 0 1rem; /* Add horizontal padding for gap between cards */
    }

    .slick-list {
      margin: 0 -1rem; /* Adjust the margins to align the cards */
    }
  }

  .card {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    width: 95%;
    margin: 0 auto;
  }

  .card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  .card:hover img {
    transform: scale(1.1);
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    text-align: center;
    padding: 1rem;
  }

  .card:hover .overlay {
    opacity: 1;
  }

  .overlay h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .overlay p {
    font-size: 1rem;
    max-width: 80%;
  }


   /* Custom dots into bars----- */

  .custom-dots-wrapper {
    display: flex !important;
    justify-content: center;
   // gap: 1rem;
    position: absolute;
    bottom: -4rem;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 5px;
      background-color: #ccc; /* Inactive color */
      border-radius: 3px;
      transition: background-color 0.3s ease;
    }

    li.slick-active {
      background-color: #0082f1; /* Active color */
    }

    .custom-dots {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .bar {
      width: 100%;
      height: 100%;
    }
  }  
    
  

 /* Responsive styles for mobile view */
  @media (max-width: 768px) {
    padding: 3rem 2rem 7rem  2rem;
    background-color: #09233c;
   
    h4{
      font-size: 4rem;
    }

   .grid-two-column {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-bottom: 3rem;

    .common-heading {
      text-align: center;
      margin-bottom: 2.5rem;
      font-weight: 900;
      color: #ffffff;
    }

    .p-text {
      font-size: 14px;
      line-height: 1.4;
    }

    .slider-container {
      .slick-slide {
        padding: 0;
      }

      .slick-list {
        margin: 0;
      }
    }

    .custom-dots-wrapper {
      bottom: -3rem;

      li {
        width: 20px;
        height: 4px;
      }
    }
  }


`;

export default CaseStudySection;
