import React from 'react';
import styled from 'styled-components';

const IndustriesSection = () => {
  return (
    <Wrapper>
      <div className="heading">
        <span>Industries</span> <span className="highlight">We Work with</span>
      </div>
      <p className="content">
      With a vast talent pool and our founder’s 18 years of experience in the tech industry, we’re
      equipped to support you across any sectors.
      </p>
      
      <div className="grid">
        <div className="grid-item">
          <div className="icon-background">
            <img src="Images/it-service.png" alt="Industry Icon 1" />
          </div>
          <p>Information Technology</p>
        </div>
        <div className="grid-item">
          <div className="icon-background">
            <img src="Images/fintech.png" alt="Industry Icon 2" />
          </div>
          <p>FinTech</p>
        </div>
        <div className="grid-item">
          <div className="icon-background">
            <img src="Images/healthcare.png" alt="Industry Icon 3" />
          </div>
          <p>Healthcare</p>
        </div>
        <div className="grid-item">
          <div className="icon-background">
            <img src="Images/autonomous.png" alt="Industry Icon 4" />
          </div>
          <p>Automotive</p>
        </div>
        <div className="grid-item">
          <div className="icon-background">
            <img src="Images/manufacturing.png" alt="Industry Icon 5" />
          </div>
          <p>Manufacturing</p>
        </div>
        <div className="grid-item">
          <div className="icon-background">
            <img src="Images/retailer.png" alt="Industry Icon 6" />
          </div>
          <p>Retail</p>
        </div>
        <div className="grid-item">
          <div className="icon-background">
            <img src="Images/property.png" alt="Industry Icon 7" />
          </div>
          <p>Real Estate</p>
        </div>
        <div className="grid-item">
          <div className="icon-background">
            <img src="Images/insurance.png" alt="Industry Icon 8" />
          </div>
          <p>Insurance</p>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 6rem 0;
  background-color: #081a30;

  .heading {
    text-align: center;
    text-transform: capitalize;
    font-size: 3rem;
    font-weight: 800;
    letter-spacing: 2px;
    color: #ffffff;
  }

  .highlight {
    background: none;
   -webkit-text-fill-color: #53daff;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: bolder;
  }

  .content {
    text-align: center;
    color: #96abc0;
    font-size: 15px;
    font-weight: 500;
    padding: 1rem 15rem;
    letter-spacing: 1px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
    grid-template-rows: repeat(2, auto); /* 2 rows */
    gap: 2rem;
    padding: 4rem 30rem;
    text-align: center;
  }

  .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon-background {
    background-color: #54d7f5;
    border-radius: 50%;
    width: 100px; /* Adjust size as needed */
    height: 100px; /* Adjust size as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .grid-item img {
    width: 50%; /* Scale icon size within circular background */
    height: 50%;
    object-fit: contain;
  //  filter: brightness(0) invert(1); /* Makes the icon white */
  }

  .grid-item p {
    margin-top: 0.5rem;
    font-size: 14px;
    color: #ffffff;
    font-weight: bolder;
  }
  
  

//  ---------------------------------------------------------------


@media (max-width: 1024px) {
    .grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;
    }
    .content {
      padding: 1rem 4rem;
    }
  }




  @media (max-width: 768px) {

    background-color: #081b2e;

    .heading {
      font-size: 4rem;
      color: #ffffff;
    }
     .highlight {
    background: none;
    -webkit-text-fill-color: #53daff;
    }  
    .content {
      font-size: 14px;
      padding: 1rem 2rem;
    }
    .grid {
     // grid-template-columns: 1fr;
      padding: 3rem 3rem;
    }


    .icon-background {
    background-color: #53daff;
    border-radius: 50%;
    width: 100px; /* Adjust size as needed */
    height: 100px; /* Adjust size as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
  }
    .grid-item img {
    width: 50%; /* Scale icon size within circular background */
    height: 50%;
    object-fit: contain;
    filter: none; /* Makes the icon white */
  }

  .grid-item p {
    color: #ffffff;
    font-weight: bolder;
   }


  }
  


  // @media (max-width: 480px) {
  //   .heading {
  //     font-size: 2rem;
  //   }
  //   .content {
  //     padding: 1rem;
  //     font-size: 0.8rem;
  //   }
  //   .grid-item p {
  //     font-size: 12px;
  //   }
  // }
  
  `;




export default IndustriesSection;
