
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaLinkedinIn, FaChevronLeft, FaChevronRight } from "react-icons/fa";

const IntroSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Replace images array with videos
  const videos = [
    { src: "./Videos/Airport.mp4", title: "- Relocation support for newly hired Indian resources", color: "#ffffff"},
    { src: "./Videos/VideoGame.mp4", title: "- Access to top Indian tech talent", color: "#ffffff" },
    { src: "./Videos/Developer.mp4", title: "- Tech solution development powered by Indian expertise", color: "#ffffff" },
    { src: "./Videos/VideoEditor.mp4", title: "- Marketing and video production support", color: "#ffffff" },
  ];

  const totalSlides = videos.length;

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
    }, 5000); // Change slide every 5 seconds
    return () => clearInterval(slideInterval);
  }, [totalSlides]);

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? totalSlides - 1 : prevSlide - 1));
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  };

  return (
    <>
      <SectionContainer>
        {/* Video Carousel */}
        <Carousel>
        {videos.map((video, index) => (
          <Slide key={index} active={index === currentSlide}>
            <Video src={video.src} type="video/mp4" title={video.title} autoPlay muted loop playsInline />
            {index === currentSlide && (
              <CarouselContent>
                <Heading>
                  Leverage Indian talent & technology to build innovative solutions
                  <br />
                </Heading>
                <SubText>{video.title}</SubText>
              </CarouselContent>
            )}
          </Slide>
        ))}

        {/* Navigation Arrows */}
        <Arrow direction="left" onClick={goToPreviousSlide}>
          <FaChevronLeft />
        </Arrow>
        <Arrow direction="right" onClick={goToNextSlide}>
          <FaChevronRight />
        </Arrow>
      </Carousel>

        <Overlay>
          <ContentContainer>
            {/* Navbar */}
            <Navbar>
              <NavList>
                <NavItem>
                  <StyledNavLink to="/" exact>
                    Home
                  </StyledNavLink>
                </NavItem>
                <NavItem onClick={() => document.getElementById('about').scrollIntoView({ behavior: 'smooth' })}>
                  <StyledNavLink>About</StyledNavLink>
                </NavItem>
                <NavItem onClick={() => document.getElementById('service').scrollIntoView({ behavior: 'smooth' })}>
                  <StyledNavLink>Services</StyledNavLink>
                </NavItem>
                <NavItem onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })}>
                  <StyledNavLink>Contact</StyledNavLink>
                </NavItem>
              </NavList>

              <IconContainer>
                <a
                  href="https://www.linkedin.com/company/innovate-ideaz-technology/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn />
                </a>
                {/* <FaTwitter />
                <FaInstagram /> */}
              </IconContainer>
            </Navbar>

            {/* Footer */}
            <FooterNavbar>
              <FooterHeading>OUR STORIES</FooterHeading>
              <BoxContainer>
                <Box>
                  <BoxRow>
                    <strong className="main-strong">700+</strong>
                  </BoxRow>
                  <BoxRow>
                    <strong>Professionals relocated</strong>
                  </BoxRow>
                </Box>
                <Box>
                  <BoxRow>
                    <strong>Indians joined at</strong>
                  </BoxRow>
                  <BoxRow>
                    <strong className="main-strong">800+</strong>
                  </BoxRow>
                  <BoxRow>
                    <strong>Companies</strong>
                  </BoxRow>
                </Box>
                <Box>
                  <BoxRow>
                    <strong className="main-strong">1500+</strong>
                  </BoxRow>
                  <BoxRow>
                    <strong>Indian Professionals hired </strong>
                  </BoxRow>
                </Box>
                <Box>
                  <BoxRow>
                    <strong className="main-strong">15</strong>
                  </BoxRow>
                  <BoxRow>
                    <strong>Projects delivered</strong>
                  </BoxRow>
                </Box>
                <Box>
                  <BoxRow>
                    <strong className="main-strong">5x</strong>
                  </BoxRow>
                  <BoxRow>
                    <strong>ROAS Generated</strong>
                  </BoxRow>
                </Box>
              </BoxContainer>
            </FooterNavbar>
          </ContentContainer>
        </Overlay>
      </SectionContainer>
    </>
  );
};

// Styled components for the section
const SectionContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: #081a30;

.main-strong {
  color: #09233c;
  font-size: 3rem;
  font-weight: 900;
  -webkit-text-stroke: 2px #09233c;
  letter-spacing: 2px;
}
`;

const Heading = styled.h1`
    font-size: 2.5rem;
    margin: 0;

     @media (max-width: 768px) {
    font-size: 1.8rem; /* Adjusted for mobile */
  }
`;

const SubText = styled.p`
    font-size: 1.8rem;
    margin-top: 1rem;

     @media (max-width: 768px) {
    font-size: 1.2rem; /* Smaller font size for mobile */
  }
  }
`;

const Carousel = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
`;

const Slide = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 1s ease-in-out;
  opacity: ${(props) => (props.active ? 1 : 0)};
`;

const Video = styled.video`
  width: 100%;
  height: 500px;
  object-fit: cover;
  muted: true;
  playsInline: true;
`;

const CarouselContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  color: #fff;
  text-align: center;
`;

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props) => (props.direction === "left" ? "left: 10px;" : "right: 10px;")}
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 1rem;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.7);
    color: #000;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 // background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  color: #fff;
  text-align: center;
`;

const ContentContainer = styled.div`
  width: 90rem;
  margin-top: -2rem;
  margin-bottom: -2rem;
`;
const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
 background: linear-gradient(to bottom, #102d4a, #1f476d);
  padding: 1rem 2rem;

  @media(max-width: 768px){
    display: none;
  }
`;

const NavList = styled.ul`
  display: flex;
  gap: 2rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const NavItem = styled.li`
  font-size: 1.8rem;
  font-weight: 500;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: #fff;

  &:hover {
    color: #0056b3;
  }
`;

const IconContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  font-size: 1.8rem;
  color: #ffffff;

  svg {
    cursor: pointer;
    color: #ffffff;
    transition: color 0.3s;

    &:hover {
    color: #f0a500;
    }
  }
`;





const FooterNavbar = styled.div`
  background: linear-gradient(to bottom, #102d4a, #1f476d);
  padding: 1.5rem;
  border-radius: 10px 10px;
  margin-top: 42rem;
  padding-bottom: 3rem;

  @media (max-width: 768px) {
    margin: 42rem 2rem 2rem 2rem;
    padding: 1rem;
    border-radius: 10px 10px;
    background: linear-gradient(to bottom, #0d2843, #20486e);
  }
`;

const FooterHeading = styled.h2`
  font-size: 3.8rem;
  color: #fff;
  margin: 1rem;

   @media (max-width: 768px) {
    font-size: 2.5rem; /* Reduce font size on smaller screens */
    text-align: center; /* Center align for mobile */
  }
`;

const BoxContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  margin-top: 1rem;

  @media (max-width: 768px) {
   // flex-direction: column; 
    gap: 0.5rem; 
  //  align-items: center; 
    flex-wrap: wrap;
  }
`;

const Box = styled.div`
  background: #e8ecef;
  padding: 4px;
  border-radius: 8px;
  flex: 1;
  color: #333;
  text-align: center;
  font-size: 1.2rem;

  @media (max-width: 768px) {
   font-size: 1rem;
   padding: 0rem 2rem;
   width: 100%;
  }
`;

const BoxRow = styled.div`
  margin: 0.5rem 0;

  strong {
    color: #111111;
    font-size: 1.5rem;
  }

  @media (max-width: 768px) {
      font-size: 1.2rem; /* Adjust font size for mobile */
    }
`;

export default IntroSection;


























