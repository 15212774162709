import React from 'react';
import styled from 'styled-components';
import { Button } from './Button';

const BusinessSection = () => {
  return (
    <>
      <Upper className='upper-section' >
        eeeeeeeeee
      </Upper>

      <Wrapper id="service">
        <div className="heading">
          Our Services - <span>Your Business </span> <span className="highlight">Made Easy</span>
        </div>
        {/* <div className="heading">
          
        </div> */}
        <p className="content">
          Focus on your business’s core areas—leave the rest to us.
        </p>

        <div className="layout-container">
          <div className="content-box left">
            <div className="box">
              <img
                className="badge"
                src="Images/relocate.png"
                alt="Badge 1"
              />
              <h3>Relocation Support</h3>
              <p>Many employers hesitate to hire international talent due to visa uncertainties
                and timing concerns. With our expertise, we simplify the process, ensuring your
                new hire can relocate to the EU within just one month. Trust us to make transitions
                seamless for both you and your team
              </p>
            </div>
            <div className="box">
            <img
                className="badge"
                src="Images/search.png"
                alt="Badge 1"
              />
              <h3>Hire Expert Indian Talent</h3>
              <p>Finding the right talent in Europe can be challenging, while India is renowned for IT expertise.
                With 18+ years of management’s experience, our best recruitment team connect you to top-tier talent
                across technologies, from development to AI, leveraging a pool of <b>550,000 </b>skilled professionals.
              </p>
            </div>
          </div>


          {/* -------------------  Center Image ---------------------- */}
          <div className="center-image-wrapper">
          <div className="center-image">
            <img src="Images/services.png" alt="Company Logo" />
          </div>
          </div>


          <div className="content-box right">
            <div className="box">
            <img
                className="badge"
                src="Images/development.png"
                alt="Badge 1"
              />
              <h3>Application Development</h3>
              <p>Hiring dedicated resources for every project is costly & impractical for you, especially in
                today’s AI-driven world. At Innovate Ideaz, we leverage a pool of <b>550,000 </b> professionals across
                multiple areas—frontend, backend, cloud, AI, and more. Our experts, including Generative AI specialists,
                deliver innovative solutions, with a chatbot launch coming soon.

              </p>
            </div>
            <div className="box">
            <img
                className="badge"
                src="Images/media.png"
                alt="Badge 1"
              />
              <h3>Marketing Strategy & Video <br/> Production</h3>
              <p>We’ve scaled businesses <b>10X</b> in one year, generating <b> €1.2 million</b> in revenue through our marketing and
                video production expertise. With top-tier video editing services, we help you stand out and maximize ad
                spend returns. Leveraging India’s best editors, we deliver exceptional results at an affordable cost.
              </p>
            </div>
          </div>
        </div>
        <div>
          <Button onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })} > Reach Us</Button>
        </div>
      </Wrapper>
    </>
  );
};


const Upper = styled.div`
  background-color: #081a30;
  height: 20rem;

@media (max-width: 768px) {
    height: 32rem; /* Adjust height for mobile */
    background-color: #0a1c2f; /* Optional: change background color */
  }
}
`;


const Wrapper = styled.section`
  padding: 6rem 0;
 //margin-top: 20rem;
  background-color: #09233c;
  text-align: center;


  .heading {
    text-transform: capitalize;
    font-size: 3rem;
    font-weight: 800;
    letter-spacing: 2px;
    color: #ffffff;
  }

  .highlight {
   background: none;
   -webkit-text-fill-color: #53daff;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: bolder;
  }

  .content {
    color: #96abc0;
    font-size: 15px;
    font-weight: 500;
    padding: 1rem 15rem;
    letter-spacing: 1px;
  }

  .layout-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 4rem;
    gap: 1rem;
  }   

 .center-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background-color: #081b2e; 
  }
  .center-image {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    border: 15px solid #53daff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .center-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content-box {
    display: flex;
    flex-direction: column;
    gap: 7.5rem;
  }
  
  .box img {
    width: 30px;
    height: 30px;
    margin-bottom: 0.5rem;
  }


  .box {
    position: relative; /* Set position to relative to position badge inside */
    width: 300px;
    height: 180px;
    background-color: #e7e8ec;
    border-radius: 10px;
    border: 1.5px solid #0082f1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15), 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .box h3 {
    color: #111111;
    font-size: 1.5rem;
    margin: 0.5rem 0;
    font-weight: bolder;
    text-align: left;
  }

  .box p {
    font-size: 12px;
    color: #565454;
    margin: 0;
    text-align: left;
  }

  .box .badge {
    position: absolute; /* Position the badge inside the box */
    top: 8px;
    right: 8px;
    width: 30px; /* Adjust size of the badge */
    height: 30px;
    z-index: 10;
  }

  @media (max-width: 768px) {
    .box {
      width: 100%;
      height: auto;
      padding: 1rem;
      font-size: 12px;
      border: none;
      background-color: #e6e8ea;
    }

    .box .badge {
      width: 25px; /* Adjust size for mobile view */
      height: 25px;
    }
  }



/* Responsive styles for mobile view */
  @media (max-width: 768px) {
         padding: 4rem 3rem;
        // margin-top: 12rem;
        background-color: #09233c;
        
        display: flex;
        flex-direction: column;
        align-items: center;

    .upper-section{
      height: 32rem;
    }    

    .heading {
      font-size: 4rem;
      color: #ffffff;
    }
    
  .highlight {
    background: none;
    -webkit-text-fill-color: #53daff;
  }

    .content {
      font-size: 14px;
      padding: 1rem 2rem;
    }

    .layout-container {
      flex-direction: column;
      gap: 2rem;
      padding: 0 18rem;
      width: 69rem;
      margin-top: 2rem;
    }

    .center-image-wrapper {
      display: none;
    }

    .center-image {
      display: none;
    }

    .content-box {
      gap: 3rem;
    }

    .box {
      width: 100%;
      height: auto;
      padding: 1rem;
      font-size: 12px;
      border: none;
      background-color: #e6e8ea;
    }
  }



`;

export default BusinessSection;
