import React from 'react';
import styled from 'styled-components';

const FounderSection = () => {
  return (
    <Wrapper id='about'>
      <div className="container">
        <GridContainer>

          <IntroSection>
            {/* <Title>    <span className="highlight">WHY WORK WITH INNOVATIVE IDEAS?</span>  </Title> */}
            <FounderHeading>About Us  </FounderHeading>
            <FounderHeading style={{ fontSize: "2rem", margin: "1rem 0 1rem 0" }}>Sandeep Khaira</FounderHeading>
            <Title>
              <span className="highlight" style={{ margin: "2rem 0 2rem 0" }} >Founder & CEO, Innovate Ideaz Technology</span>

            </Title>
            <p className='Founder-content' style={{ margin: "1rem 0 1rem 0" }}>
              Sandeep Khaira is a trailblazer in the fields of international relocation from India, hiring and
              application development, with over 18 of experience across India & Europe in transforming
              business operations through innovative technology. A passionate entrepreneur and thought
              leader, Sandeep Khaira founded his 2 nd venture Innovate Ideaz Technology with a clear mission:
              to revolutionize the way businesses connect with top Indian talent and build scalable, user-
              centric applications & drive business across sectors leveraging Indian expert professionals
              across fields.
            </p>


            <div className='Founder-two'>
              <div className='f-image-mobile'>

                <CircularImage src="Images/Founder.jpg" alt="Founder Image" />
              </div>

              <div>
                {/* 
                <Heading>Why work with us?</Heading>
                <hr />
                <Description>
                  Having worked at the intersection of relocation, recruitment and software development,
                  Sandeep Khaira saw firsthand the challenges that companies face in streamlining relocation,
                  hiring processes and creating efficient digital solutions. This inspired him to create Innovate
                  Ideaz Technology, a platform designed to empower businesses by bridging the gap between
                  people and technology.
                </Description> */}

                <Heading>Our Achievements
                </Heading>
                <hr />
                <Description className='Founder-content'>
                  Under Sandeep Khaira’s leadership, Innovate Ideaz Technology has achieved remarkable
                  milestones, helping 800+ businesses across industries improve their hiring outcomes and
                  develop robust, customized applications. Sandeep Khaira’s commitment to excellence has
                  earned recognition as a trusted partner in solving modern workforce and technological
                  challenges.
                  {/* </Description>

                <Heading>Scalable</Heading>
                <hr />
                <Description> */}
                  Beyond professional accomplishments, Sandeep believes in fostering innovation and
                  collaboration. He often shares insights as a mentor, speaker, and advocate for leveraging
                  technology to create meaningful impact.
                  With Sandeep Khaira at the helm, Innovate Ideaz Technology continues to redefine success for
                  businesses, helping them adapt, grow, and thrive in an ever-changing digital landscape.
                </Description>
              </div>
            </div>


          </IntroSection>



          {/* Second Column: Image with Content */}

          <div>

            <ImageColumn>
              <CircularImage src="Images/Founder.jpg" alt="Founder Image" />

            </ImageColumn>
            <IntroSection>
              <Heading>Why work with us?</Heading>
              <hr />
              <Description className='Founder-content'>
                Having worked at the intersection of relocation, recruitment and software development,
                Sandeep Khaira saw firsthand the challenges that companies face in streamlining relocation,
                hiring processes and creating efficient digital solutions. This inspired him to create Innovate
                Ideaz Technology, a platform designed to empower businesses by bridging the gap between
                people and technology.
              </Description>
            </IntroSection>
          </div>

        </GridContainer>
      </div>
    </Wrapper>
  );
};

// Styled Components
const Wrapper = styled.section`
  padding: 6rem 10rem;
  background-color: #09233c;

   

  @media(max-width: 768px){
    padding: 3rem 2rem;

  .highlight {
    background: none;
    -webkit-text-fill-color: #53daff;
  }  
  }

.Founder-two{
   display: flex;
   gap: 2rem;
   margin-top: 2rem;

   @media (max-width: 768px) {
      flex-direction: column;     
      gap: 0rem; 
    }


}

   .f-image-mobile{
     display: none;

     @media(max-width: 768px){
      display: flex ;
      justify-content: center; */
      align-items: center;
     }
   }
  
 

  .Seventeen {
    color: #ffffff;
    font-size: 4rem;
    font-weight: bolder;


     @media (max-width: 768px) {
      font-size: 3rem;
    }

    @media (max-width: 480px) {
      font-size: 2.5rem;
    }
  }

 .Founder-content{
    font-size: 1.3rem;
    color: #96abc0;
    font-weight: 500;


    @media (max-width: 768px) {
      font-size: 13px;
    }

 }



.highlight {
    text-transform: capitalize;
     background: none;
   -webkit-text-fill-color: #53daff;
    background-clip: text;
    color: transparent; 
    font-weight: bolder;
    letter-spacing: 1px;
}

`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: start;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 0rem;
  }
`;


const IntroSection = styled.div`
  display: flex;
  flex-direction: column;
 // gap: 1rem;
  max-width: 600px;
  padding: 2rem;

  @media (max-width: 480px) {
    padding: 0rem 1rem 0rem 1rem;
  }
  
`;

const FounderHeading = styled.h3`
  font-size: 3.5rem;
  font-weight: bold;
  letter-spacing: 1px;
  //margin: 1rem;
  color: #ffffff;


   @media (max-width: 768px) {
      font-size: 4rem;
      
  }

  // @media (max-width: 480px) {
  //   font-size: 2.5rem;
  // }


`;
const Title = styled.span`
  font-size: 1.4rem;
  font-weight: bold;
  color: #383838;



   @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;


const Description = styled.p`
  font-size: 1rem;
  color: #b5b5b5;
  line-height: 1.6;
  margin: 1rem 0;



  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  // @media (max-width: 480px) {
  //   font-size: 0.8rem;
  // }
`;


const Heading = styled.h3`
  font-size: 1.5rem;
  //font-weight: bold;
  margin: 1rem 0;
  color: #ffffff;


   @media (max-width: 768px) {
    font-size: 1.3rem;
  }

  // @media (max-width: 480px) {
  //   font-size: 1.1rem;
  // }
`;



// Second Column Styling
const ImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem;


  @media (max-width: 768px) {
    margin: 1.5rem;
    display: none;
    gap: 1rem;
  }
`;


const CircularImage = styled.img`
  width: 350px;
  height:  350px;
  border-radius: 50%;
 // border: 4px solid #0082f1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  object-fit: cover;
  margin-bottom: 1rem;


  @media (max-width: 768px) {
    width: 300px;
    height: 300px;
  }

  // @media (max-width: 480px) {
  //   width: 200px;
  //   height: 200px;
  // }

  
`;


export default FounderSection;
