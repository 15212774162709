import { createGlobalStyle } from "styled-components";
//@import url('https://fonts.googleapis.com/css2?family=Manrope&family=Poppins&family=Roboto:wght@300;400&display=swap');



export const GlobalStyle = createGlobalStyle`
  

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Manrope", sans-serif;
}
  html {
  font-size: 62.5%;
  /* scroll-behavior: smooth; */
  /* 1rem = 10px */
  overflow-x: hidden;
}


body {
  overflow-x: hidden;
  scrollbar-color: rgb(98 84 243);
  scrollbar-width: thin;
}

body::-webkit-scrollbar {
  width: 1.5rem;
}

body::-webkit-scrollbar-track {
   background-color: rgb(24 24 29);
}

body::-webkit-scrollbar-thumb {
 
  background: #fff;
    border: 5px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
}





${"" /* resuable code section  */}

.container {
  max-width: 120rem;
  margin: 0 auto;
}

.grid {
  display: grid;
  gap: 9rem;
}

.grid-two-column {
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
}

.grid-three-column {
  grid-template-columns: repeat(3, 1fr);
}

.grid-four-column{
   grid-template-columns: 1fr 1.2fr .5fr .8fr ;
}

.grid-five-column{
  grid-template-columns: repeat(5, 1fr);
}



 .common-heading {
      font-size: 3.2rem;
      font-weight: 600;
      margin-bottom: 6rem;
      text-transform: capitalize;
    }

  .heading-center{
    display : flex;
    justify-content: center;
  }  

  .p-text{
    font-size : 15px;
  
  }



`