import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Button } from "./Button";


const Footer = () => {
  return (
    <>
      <Wrapper>
        <section className="contact-short">
          <div className="grid grid-two-column">
            <div>
              <h3>Ready to get started?</h3>
              <h3>Talk to us today</h3>
            </div>

            <div>
              <Button className="btn hireme-btn">
                <NavLink to="/"> Get Started </NavLink>
              </Button>
            </div>
          </div>
        </section>



        {/* footer section */}

        <footer>
          <div className="container ">
            <div className="footer-about" onClick={() => document.getElementById('header').scrollIntoView({ behavior: 'smooth' })}>
              <img src="Images/logo1.png" className="footer-logo" />
            </div>

            <div className="footer-three">
              <div className="footer-social">
                <h3 >Our Services </h3>
                <h2 onClick={() => document.getElementById('service').scrollIntoView({ behavior: 'smooth' })} >Relocation Support</h2>
                <h2 onClick={() => document.getElementById('service').scrollIntoView({ behavior: 'smooth' })} >Hire Expert Indian Talent</h2>
                <h2 onClick={() => document.getElementById('service').scrollIntoView({ behavior: 'smooth' })} >Application Development</h2>
                <h2 onClick={() => document.getElementById('service').scrollIntoView({ behavior: 'smooth' })} >Marketing Strategy & Video Production</h2>
              </div>

              <div className="footer-contact">
                <h3>Contact Info</h3>
                <h2>(+49) 176-8227-4604</h2>
                <h2>contact@innovateideaz.com</h2>
              </div>

              <div className="footer-subscribe">
                <h3> NewsLetter </h3>
                <p style={{padding: "0rem"}}>We empower you through relocating Indian tech talent, hiring and technical solution development.
                </p>
                <form action="#">
                  <input type="email" name="email" placeholder="YOUR E-MAIL" />

                  <Button className="subscribe">Reach Us</Button>
                </form>
              </div>
            </div>
          </div>


          <div className="footer-bottom--section">
            <hr />
            <div className="grid grid-two-column center ">
              <p className="term-head">
                All Rights Reserved.
              </p>
              <div className="terms-row">

                <p >
                  TERMS & CONDITIONS
                </p>
                <p>
                  PRIVACY POLICY
                </p>
                <p>
                  IMPRINT
                </p>
              </div>

            </div>
          </div>
        </footer>
      </Wrapper >
    </>
  );
};

const Wrapper = styled.section`
  .iSIFGq {
    margin: 0;
  }

  .center{
    justify-items: center;
    align-items: center;
  }

  .terms-row{
    display: flex;
    gap: 3rem;
  }

   .term-head{
      font-size: 1.5rem;
    } 
 
  .contact-short {
    max-width: 60vw;
    margin: auto;
    padding: 5rem 10rem;
    background-color: #F6F8FA;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    transform: translateY(50%);

    display: none;

    .grid div:last-child {
      justify-self: end;
      align-self: center;
    }
  }



  footer {
    padding: 4rem 0 2rem 0;
    background-color: #09233c;

    h3 {
      font-size: 1.5rem;
      color: #ffffff;
      margin-bottom: 1.8rem;
      position: relative;

      /* Adding green underline */
      &::after {
        content: "";
        position: absolute;
        bottom: -10px; /* Adjust position as needed */
        left: 40%;
        transform: translateX(-130%);
        width: 30%; /* Half width */
        height: 2px;
        background-color: green; /* Green color for underline */
      }
    }
     
    h2{
     color: #c7c7c7;
     font-size: 1.2rem;
      margin-bottom: 1rem;
      cursor: pointer;

      @media (max-width: 768px) {
        font-size: 1rem;       
     }
    }

    p {
      color: #fff;
    }

    .container {
      display: flex;
     // align-items: center;
      padding: 5rem 3rem 2rem 3rem;
     justify-content: space-evenly;
      
     .footer-about {
        flex: 0.1  !important;  
        text-align: center;
        cursor: pointer;

        .footer-logo {
          width: 200px; /* Smaller width for logo */
          height: auto;
          margin-top: 1rem;
        }
      }

      .footer-about,
      .footer-social,
      .footer-contact,
      .footer-subscribe {
        flex: 1;
        //text-align: center;
      }
    }

form{
   display: flex;
   flex-wrap: wrap;
   margin-top: 15px;

   }
   .subscribe{
    padding: 1rem 5rem;
    font-size: 1.3rem;
    margin-top: 1rem;
  }
   input{
    padding: 1rem 2rem;
    font-size: 1.3rem;
    margin-top: 1rem;
     border-radius: 4px;
     flex: 1; /* Allow inputs to take equal space in each row */
 
      @media (max-width: 768px) {
         font-size: 0.9rem;
         border:none;
     }
   } 


    .footer-logo {
      width: 150px;
      height: auto;
      margin-top: 1rem;
    }
  }

  .footer-three{
    display: flex;
    align-items: flex-start;
    gap: 1rem; 
       
  }

  .footer-bottom--section {
    padding-top: 4rem;

    hr {
      margin-bottom: 2rem;
      color: #ffffff;
      height: 0.1px;
    }
  }

  /* Media Query for Mobile Devices */
  @media (max-width: 768px) {
    footer {
      padding: 1rem 0 1rem 0;
      background-color: #09233c;

      .center{
        gap: 1rem;
      }

      .container {
        display: flex;
        flex-direction: column-reverse;
        gap: 2rem;

        .footer-about {
          order: 1;
          text-align: center;

          .footer-logo {
            margin: 0 auto;
          }
        }

       .footer-three{
         display: flex;
         align-items: flex-start;
         flex-wrap: wrap;
         gap: 2rem;
         padding: 3rem 2rem 2rem 2rem;
       }


      }
      form{
       display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    flex-direction: column;
      }
    }
   

    .terms-row{
      display: flex;
      font-size: 1rem;
      letter-spacing: 1px;
    }
    .term-head{
      font-size: 1.5rem;
    }  

    p{
      padding: 0 1rem;
    }
     .grid-two-column{
       grid-template-columns: repeat(1, 1fr);
     } 

    .footer-bottom--section {
      padding-top: 2rem;
      text-align: center;
    }
  }
`;

export default Footer;

